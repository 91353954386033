/**
 * bodyScroll
 *
 * https://github.com/FL3NKEY/scroll-lock
 */

import { disablePageScroll, enablePageScroll } from 'scroll-lock';

export default function bodyScrollLock() {
  
  let $scrollableElement 
     $scrollableElement = document.querySelector('.swiped .swipe-grid'),
                          document.querySelector('.full .main');

  disablePageScroll($scrollableElement);
  enablePageScroll($scrollableElement);
}