/**
 * Smooth Scroll
 * https://github.com/tsuyoshiwada/sweet-scroll
 */

// import SweetScroll from 'sweet-scroll';
// 
// export default function smoothScroll() {
//   const sweetScroll = new SweetScroll({ /* some options */ });
// }

import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
import Smooth from 'smooth-scrolling';
import Custom from './custom';

let fullPage = document.querySelector('body.full');
let fullProject = document.querySelector('body.full--project');
let fulltextPage = document.querySelector('body.fullText');
  
export default function smooth() {
  if (fullPage) {
    const container = document.querySelector('.page-container');
    const first = document.querySelector('.page-section--first');
    const sections = gsap.utils.toArray('.page-section');
    let snapSections = gsap.utils.toArray(".page-section.snap"), snapper;
    
    const second = sections[1];
    const lastIndex = sections.length - 1;
    
    let section_hero = document.querySelector(".page-section__hero");
    let section_intro = document.querySelector(".page-section__intro");
    const introHeight = section_intro.clientHeight;
    const heroHeight = section_hero.clientHeight;
        
    
    ScrollTrigger.matchMedia({
    // desktop
    "(min-width: 800px)": function() {
      // setup animations and ScrollTriggers for screens 800px wide or greater (desktop) here...
      // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
      first.style.height = heroHeight + 'px';
    },

    // mobile
    "(max-width: 799px)": function() {
      // The ScrollTriggers created inside these functions are segregated and get
      // reverted/killed when the media query doesn't match anymore. 
      first.style.height = (heroHeight + introHeight) + 'px';
    },
  	
    // all 
    "all": function() {
      // ScrollTriggers created here aren't associated with a particular media query,
      // so they persist.
    }
  	
  });
  
    gsap.set(section_intro, { yPercent: 10 })
    gsap.set(section_hero, { backgroundPosition: "30%" + -(heroHeight) / 2 + "px" })
    
    gsap.to(section_intro, { 
      duration: 2, 
      yPercent: 0, 
      ease: "power4",
      scrollTrigger: {
        trigger: "first",
        scrub: true,
        start:"top 75%", //when top of herman passes 75% viewport height
        end:"bottom 25%",
        //markers: true
      }
     })
    
    gsap.to(section_hero, { 
      duration: 2, 
      backgroundPosition: "30% 0px",
      ease: "power4",
      scrollTrigger: {
        trigger: section_hero,
        scrub: true,
        start:"top 75", //when top of herman passes 75% viewport height
        end:"bottom 25%",
        //markers: true
      }
     })
     
     
    gsap.utils.toArray(snapSections).forEach(function(elem, i) {
      
      let tl = gsap.timeline({
         scrollTrigger: {
           //markers: true,
           trigger: snapSections[0],
           start: ()=> i==0 ? "top top-=100px" : "top bottom", // The FIRST section will use a different start value than the rest
           end: ()=> i==lastIndex ? "top top" : "bottom top", // The LAST section will use a different start value than the rest
           onRefresh: self => {
            // translate the offsetTop of each section into a progress value between the ScrollTrigger's start and end for snapping
            let values = snapSections.map(elem => gsap.utils.normalize(self.start, self.end, elem.offsetTop));
            values.push(1); // make sure it can snap to the end of the last section.
            snapper = gsap.utils.snap(values); // create a function that'll do the snapping for us. Just pass in a value and it'll return the closest one in the Array.
          },
          snap: value => snapper(value)
        }
      },{delay: 0.5})
       tl.fromTo(elem,  {
         //y: "150%",
         scale: 1,
         duration: 1.5,
         stagger: 1.5,
         overwrite: "auto",
         delay:0,
       },
       {
         //y: "0%",
         scale: 1,
         duration: 1.75,
         stagger: 1.5,
         delay:1,
         overwrite: "auto",
         ease: "power4.inOut"
       });
    });
  }
  
  // End good looking scrolling on plain full pages.
  
  if (fullProject) {
    
    const section = document.querySelector('.vs-section');
    const opacity = document.querySelector('.vs-section__inner');
    
    const height = opacity.clientHeight;
    
    let scrollVal;
    const smooth = new Custom ({ 
      native: true,
      preload: true,
      section: section,
      opacity: opacity,
      ease: 0.2,
    
      // Make sure ScrollTrigger knows what value to use
      callback: val => {
        scrollVal = val;
        ScrollTrigger.update();
      }
    });
    smooth.init();
    // Use the same function for left and top since usually only one is used
    function getSetPos(value) {
      if (arguments.length) { // setter
        smooth.vars.current = value;
        smooth.vars.target = value;
      }
      console.log(scrollVal);
      return scrollVal; // getter
    }
    
    ScrollTrigger.scrollerProxy(".vs-section__inner", {
      scrollTop: getSetPos,
      scrollLeft: getSetPos
    });
    
    let overlap = '-=0.3';
    gsap.defaults({ ease: "power4", duration: 1 });
    gsap.set(".project__item-imgwrap", {y: 100});
    
    ScrollTrigger.batch('.project__item-imgwrap', {
      interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
      batchMax: 3,   // maximum batch size (targets)
      onEnter: batch => gsap.to(batch, {autoAlpha: 0.8, stagger: 0.15, overwrite: true}),
      onLeave: batch => gsap.set(batch, {autoAlpha: 0, overwrite: true}, overlap),
      onEnterBack: batch => gsap.to(batch, {autoAlpha: 0.8, stagger: 0.15, overwrite: true}),
      onLeaveBack: batch => gsap.set(batch, {autoAlpha: 0, overwrite: true}, overlap),
      //markers: true
    });
    
    ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".project__item-imgwrap", {y: 0}));
  }
  
  if (fulltextPage) {
    const containerText = document.querySelector('.bodytext');
    
    const height = containerText.clientHeight;

    const textBox = document.querySelector(".bodytext");
    const textWrapper = document.querySelector(".col-section-2--third");
    const innerHeight = textBox.getBoundingClientRect().height;
    
    textWrapper.style.height = height + "px";
    let snapSections = gsap.utils.toArray(".bodytext p"),
    snapper;
    
    gsap.utils.toArray(".bodytext p").forEach(target => {
    const tl = gsap.timeline({
      defaults: {duration: 1},
      y: () => -(height - document.documentElement.clientHeight),
      ease: "power4",
      force3D: false, 
      scrollTrigger: {
        //markers: true,
        pinReparent: true,
        trigger: target,
        scrub: 1,
        start: "top 90%",
        end: "bottom bottom",
        onRefresh: self => {
          // translate the offsetTop of each section into a progress value between the ScrollTrigger's start and end for snapping
          let values = snapSections.map(section => gsap.utils.normalize(self.start, self.end, section.offsetTop));
          values.push(1); // make sure it can snap to the end of the last section.
          snapper = gsap.utils.snap(values); // create a function that'll do the snapping for us. Just pass in a value and it'll return the closest one in the Array.
        },
        snap: value => snapper(value)
      }
    })
    .fromTo(target, {y: 50}, {y: -50})
    .from(target, {opacity: 0, duration: 0.2}, 0)
    .to(target, {opacity: 1, duration: 0.2}, 0.8)
    });
  }
}

