/*
 * @title App
 * @description Application entry point
 */

// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Modules
import navbar from 'Modules/navbar/navbar';
import swipe from 'Modules/swipe/swipe';
import smooth from 'Modules/smooth-scroll/smooth-scroll';

// Components
import collapse from 'Components/collapse.js';
import toggleElement from 'Components/toggle-element';
import bodyScrollLock from 'Components/bodyscroll';

document.addEventListener('DOMContentLoaded', function() {
  
  // Modules
  //carousel();
  navbar();
  //searchOverlay();
  swipe();
  smooth();

  // Components
  collapse();
  toggleElement();
  bodyScrollLock();

})
