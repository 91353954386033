/**
 * Menureveal
 */

import { gsap } from 'gsap';

const actionBtn = document.querySelector('.navbar__toggler');
const menuItems = document.querySelectorAll(".menu__item");
let menuActive = false;

actionBtn.addEventListener("click", () => {
	menuActive = !menuActive;
	actionBtn.classList.toggle("close");
	animateOpenCloseMenu();
});

function animateOpenCloseMenu() {
  let revealBlock = document.querySelector('.nav-js');
  let revealCircle = document.querySelector('.nav-js__inner--circle');
  
  let tl = gsap.timeline({ defaults: { ease: "power3.out", duration: 0.45, force3D: false } });
  
  if (menuActive) {
   
    tl.fromTo(
      revealBlock,
      { autoAlpha: 0 },
      { autoAlpha: 1 }
    );
		
    tl.fromTo(
			revealCircle,
      { autoAlpha: 0, scale: 0.8, transformOrigin: "center center", y: "-30%", x: "90%", zIndex: 0 },
			{ autoAlpha: 1, scale: 12, delay: -0.35,zIndex: 9999 }
		);
    tl.fromTo(menuItems, { autoAlpha: 0, left: "100%" }, { autoAlpha: 1, left: "0%", stagger: 0.05, delay: -0.35 });
	} else if (menuActive = false) {
    tl.set(revealCircle, { autoAlpha: 0 });
  } else {
    tl.fromTo(menuItems, { autoAlpha: 1, left: "0%" }, { autoAlpha: 0, left: "100%", stagger: -0.05 });
    tl.fromTo(
			revealCircle,
      { autoAlpha: 1, scale: 12, transformOrigin: "center center", y: "-30%", x: "90%" },
			{ autoAlpha: 0, scale: 0.8, delay: -0.35 }
		);
    
    tl.fromTo(
      revealBlock,
      { autoAlpha: 1 },
      { autoAlpha: 0, delay: -0.35 }
    );
  }

}
animateOpenCloseMenu();