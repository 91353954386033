import { Swiper, Mousewheel, Scrollbar } from 'swiper/js/swiper.esm.js';
import 'lazysizes';

// Install modules
Swiper.use([Mousewheel, Scrollbar]);

const horizontal = document.querySelector('body.swiped');

export default function swipe() {
  
  // Check if page has swiped class
  if (horizontal) {
    window.lazySizes.init();
    
    const swipePortfolio = new Swiper(".swiper-container", {
      direction: "vertical",
      loop: true,
      mousewheel: true,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        hide: false
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        // when window width is >= 640px
        640: {
          direction: "horizontal",
          slidesPerView: "auto",
          spaceBetween: 40,
          loop: false
        }
      }
    });
  }
}